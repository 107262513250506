export default class BalanceDataTableItem {
    dutyId = 0;
    batchCreate = 0;
    dutyStatus = 0;
    userCode = "";
    userName = "";
    dutyDate = "";
    dutyHour = "";
    dutyReason = "";
    dutyStart = "";
    dutyEnd = "";
    dutyUse = "";
    dutyInvalid = "";
    orgTypeIdName = "";
    orgTypeRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
    batchName = "";
    modifyReason = "";
    batchCreateName = "";
    batchUseName = "";
}
