import requests from "@/utils/axios";
import baseApi from "@/api/baseApi";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/attendance/duty/batch",
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
    getBatchOne(query: any) {
        return requests.post(this.modulesUrl + "/getBatchOne", query);
    },
    delBatchOne(query: any) {
        return requests.post(this.modulesUrl + "/delBatchOne", query);
    },
};
